import { CkEditorInstanceId, getCKEditorInstance, setHtmlCKEditor } from '@/annotation/ckEditor.utilities';
import _ from 'lodash';

/**
 * Gets the data from CKEditor
 */
export function getHtmlReportEditor() {
  const ckeditorInstance = getCKEditorInstance('reportEditor');
  if (!ckeditorInstance) {
    return;
  }
  return ckeditorInstance.getData();
}

/**
 * Gets the editor scroll offset
 *
 * @returns the current scroll offset
 */
export function getScrollOffset() {
  const editorMain = document.querySelector('.ck-editor__main');
  return editorMain?.scrollTop ?? 0;
}

/**
 * Sets the editor scroll offset
 *
 * @param offset - a scroll offset
 */
export function setScrollOffset(offset: number) {
  // Setting the scroll offset right after a change to the document can sometimes cause the set to be eaten by
  // something CK does.
  setTimeout(() => {
    const editorMain = document.querySelector('.ck-editor__main');
    if (editorMain) {
      editorMain.scrollTop = offset;
    }
  }, 1);
}

/**
 * Sets the document html of the report when in view mode. In view mode the document is displayed in a simple
 * div. In edit mode, the document displays in the editor.
 *
 * @param {string} document - the html to set as the report view
 */
export function setReportViewHtml(document: string) {
  setHtmlCKEditor(document, 'reportEditor');
}

export function setCursorPosition(cursorPosition: Position | null, id: CkEditorInstanceId): void {
  if (_.isNil(cursorPosition)) {
    return;
  }

  getCKEditorInstance(id)?.model.change((writer) => {
    try {
      writer.setSelection(cursorPosition);
    } catch (error) {
      // It is expected that the path can be incorrect, such as during concurrent editing and the text where another
      // had their cursor is removed. It is easier to ignore the error than try to devise our own method for figuring
      // out if it is correct. However, a correct cursor position still needs to be set to avoid CK erroring when the
      // user starts typing again.
      cursorPosition.path = [0, 0];
      writer.setSelection(cursorPosition);
    }
  });
}

export function getCursorPosition(id: CkEditorInstanceId): undefined | Position {
  return getCKEditorInstance(id)?.model.document.selection.getFirstPosition();
}

export function executeCommand<T extends { command: string; ckEditorInstanceId: CkEditorInstanceId }>({
  command,
  ckEditorInstanceId,
  ...rest
}: T): void {
  getCKEditorInstance(ckEditorInstanceId)?.execute(command, rest);
}
